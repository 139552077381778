import React, { useState } from "react";
import classes from "./EventCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "configs/AppConfig";
import { wishlistActions } from "store-redux/wishlist-slice";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { modalActions } from "store-redux/modal-slice";

function EventCard({
  listingId = "",
  image,
  title,
  review,
  city,
  state,
  price,
  strikePrice,
}) {
  console.log("listingId in home event card", listingId);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [apiLoading, setApiLoading] = useState(false);

  const removeItemFromWishlist = async (id) => {
    setApiLoading(true);
    const response = await fetch(`${API_BASE_URL}wishlist/${id}/delete`, {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Accept: "/",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();
    console.log("wishlist removal -", data);

    if (data.success) {
      setApiLoading(false);
      dispatch(
        wishlistActions.toggleWishlist({
          totalItems: data.total || data.data?.length,
          wishlist: data.data,
        })
      );
      message.error("Item successfully removed from Wishlist");
    }

    if (!response.ok) {
      setApiLoading(false);
      message.error(data.message);
      throw new Error(data.message);
    }
  };

  const addItemToWishlist = async (id) => {
    setApiLoading(true);
    const response = await fetch(`${API_BASE_URL}wishlist/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "/",
        Authorization: `Bearer ${user.sessionToken}`,
      },
      body: JSON.stringify({
        listingId: id,
      }),
    });
    const data = await response.json();
    console.log("wishlist adding -", data);

    if (data.success) {
      setApiLoading(false);
      dispatch(
        wishlistActions.toggleWishlist({
          totalItems: data.total || data.data?.length,
          wishlist: data.data,
        })
      );
      message.success("Item added to Wishlist");
    }

    if (!response.ok) {
      setApiLoading(false);
      message.error(data.message);
      throw new Error(data.message);
    }
  };

  const wishlistHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isLoggedIn) {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
      return;
    }
    if (wishlist?.some((item) => item.listingId === listingId)) {
      //to remove item from wishlist if its already in wishlist
      const wishlistItemId = wishlist.find(
        (item) => item.listingId === listingId
      );
      console.log("wishlist item to be removed", wishlistItemId);
      removeItemFromWishlist(wishlistItemId._id);
    } else {
      //to add item to wishlist if its not in wishlist
      addItemToWishlist(listingId);
    }
  };

  return (
    <div className="event-slider">
      <div className={classes.eventsOuter}>
        {apiLoading && (
          <LoadingOutlined
            style={{ fontSize: "24px" }}
            className={classes.loadingIcon}
          />
        )}
        {!apiLoading && (
          <img
            className={classes.heartIcon}
            src={
              wishlist?.some((item) => item.listingId === listingId)
                ? "/img/icons/heart-red.svg"
                : "/img/icons/heart.svg"
            }
            onClick={(e) => wishlistHandler(e)}
          />
        )}
        <img className={classes.titleImage} src={image} />
        <div className={classes.titleSection}>
          <h2>{title}</h2>
          {/* <div className={classes.reviewSection}>
            <img src="/img/star-icon.svg" />
            <h3>{review}</h3>
          </div> */}
        </div>

        <div className={classes.locationSection}>
          <img src="/img/packages/location_icon.svg" />
          <h3>
            {/* {city} , {state} */}
            {city}
          </h3>
        </div>
        <div className={classes.priceSection}>
          <h2>{price ? '₹' : ''} {price?.toLocaleString("en-IN")}</h2>
          {/* <h3>₹ {strikePrice.toLocaleString('en-IN')}</h3> */}
        </div>
      </div>
    </div>
  );
}

export default EventCard;
