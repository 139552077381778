import React from "react";
import styles from "./Blogcard.module.css";
import { useNavigate } from "react-router-dom";

const Card = ({ image, date, title, description, slug }) => {
  const dateValue = new Date(date);
  const navigate = useNavigate();

  const formattedDate = dateValue.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className={styles.card} onClick={()=>navigate(`/blog/${slug}`)}>
      <div className={styles.imageContainer}>
        <img src={image} alt={title} className={styles.image} />
      </div>
      <div className={styles.content}>
        <div className={styles.dateDiv}>
          <img src="/img/calendar-icon.svg" />
          <span className={styles.date}>{formattedDate}</span>
        </div>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

export default Card;
