import React, { useState, useEffect } from "react";
import styles from "./BlogsList.module.css";
import Card from "./blog-card/Blogcard";
import { API_BASE_URL } from "configs/AppConfig";
import WeddingPagination from "components/WeddingPagination/WeddingPagination";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";

const BlogsList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState();

  function extractTextFromHTML(htmlString) {
    return htmlString.replace(/<\/?[^>]+(>|$)/g, "").trim();
  }

  useEffect(() => {
    const fetchBlogs = async () => {
        setBlogs([])
        setLoading(true)
      try {
        // Replace with your API endpoint
        const response = await fetch(
          `${API_BASE_URL}pages/getAll?categorySlug=blogs&page=${currentPage}&limit=${itemsPerPage}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );

        if (!response.ok) {
          setBlogs([])
          throw new Error("Failed to fetch data");
          
        }
        const data = await response.json();
        console.log("fetched datas is", data);
        setBlogs(data?.data);
        setTotalItems(data?.total);
      } catch (err) {
        setBlogs([])
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [currentPage]);

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  //   if (error) {
  //     return <div>Error: {error}</div>;
  //   }

  return (
    <>
    {loading && blogs?.length==0 && ( <LoadingSpinner />)}
    {!loading && blogs?.length ==0 && ( <h4 className="home-error">No Data Found</h4>)}
    {!loading && blogs?.length > 0 && (
      <div className={styles.container}>
        <header className={styles.header}>
          <h1 className={styles.title}>Blogs</h1>
          <p className={styles.description}>
            Stay updated with expert tips and trends for seamless event
            management.
          </p>
        </header>

        <div className={styles.grid}>
          {blogs.map((card) => (
            <Card
              key={card.id}
              image={card.image}
              date={card.createdAt}
              title={card.name}
              description={extractTextFromHTML(card.homeDescription)}
              slug = {card?.slug}
            />
          ))}
        </div>
        <div classname={styles.pagination}>
          <WeddingPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalItemsCount={totalItems}
            itemsCountPerPage={8}
          />
        </div>
      </div>
      )}
    </>
  );
};

export default BlogsList;
