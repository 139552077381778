import React, { useEffect, useState } from "react";
import { Card } from "antd";
import styles from "./BlogDetail.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "configs/AppConfig";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";


const formatDate = (dateValue) => {
  console.log("date value is", dateValue);
  const date = new Date(dateValue);
  const formattedDate = date?.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return formattedDate;
};

function extractTextFromHTML(htmlString) {
    return htmlString.replace(/<\/?[^>]+(>|$)/g, "").trim();
  }

const BlogDetail = () => {
  const { slug } = useParams();
  const [blogData, setBlogData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlog = async () => {
      setBlogData({});
      setLoading(true);
      try {
        // Replace with your API endpoint
        const response = await fetch(`${API_BASE_URL}Pages/get/slug/${slug}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.sessionToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("fetched datas is", data);
        setBlogData(data?.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    const fetchBlogs = async () => {
      setBlogs([]);
      setLoading2(true);
      try {
        // Replace with your API endpoint
        const response = await fetch(
          `${API_BASE_URL}pages/getAll?categorySlug=blogs`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${user.sessionToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log("fetched datas is", data);
        setBlogs(data?.data?.filter((item)=>item?.slug != slug));
      } catch (err) {
      } finally {
        setLoading2(false);
      }
    };

    fetchBlogs();
    fetchBlog();
  }, [slug]);

  return (
    <>
    {loading && !blogData?.name && <LoadingSpinner />}
    {!loading && blogData?.name && (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <img
          src={blogData.image}
          alt={blogData.title}
          className={styles.mainImage}
        />

        <Card className={`${styles.dateCard} date-card`}>
          <div className={styles.dateDiv}>
            <img src="/img/calendar-icon.svg" />
            <p className={styles.date}>{formatDate(blogData.createdAt)}</p>
          </div>
          <h1 className={styles.title}>{blogData?.name}</h1>
        </Card>

        <Card className={`${styles.descriptionCard} date-card`}>
          <div
            className={`${styles.descr} description-content`}
            dangerouslySetInnerHTML={{ __html: blogData?.description }}
          />
        </Card>
      </div>

      <div className={styles.sidebar}>
        <h2 className={styles.sidebarTitle}>Similar Blogs</h2>
       <div className={styles.similar}>
       {!loading2 && blogs?.length ==0 && ( <h4 className="home-error">No Data Found</h4>)}
        {blogs?.slice(0,4)?.map((post) => (
          <div
            onClick={()=>navigate(`/blog/${post?.slug}`)}
            key={post.id}
            className={styles.similarPost}
          >
            <img
              src={post.image}
              alt={post.title}
              className={styles.similarPostImage}
            />
            <div className={styles.similarPostContent}>
              <div className={styles.dateDiv2}>
                <img src="/img/calendar-icon.svg" />
                <span className={styles.date}>{formatDate(post.createdAt)}</span>
              </div>
              <h3 className={styles.title2}>{post?.name}</h3>
              <p className={styles.description}>{extractTextFromHTML(post.homeDescription)}</p>
            </div>
          </div>
        ))}
        </div>
      </div>
    </div>
    )}
    </>
  );
};

export default BlogDetail;
