import React, { useEffect, useState } from "react";
import classes from "./SideNavbarList.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "store-redux/modal-slice";
import { userActions } from "store-redux/user-slice";
import NavbarItem from "../navbar-list/navbar-item/NavbarItem";
import { Badge, Button, Select } from "antd";
import Autocomplete from "react-google-autocomplete";
import { APP_PREFIX_PATH, GOOGLE_MAP_API_KEY } from "configs/AppConfig";
import { filterActions } from "store-redux/filter-slice";

const { Option } = Select;

const SideNavbarList = ({ className, onClose }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.user);
  console.log("user data", user);
  const filters = useSelector((state) => state.filter);
  console.log("filter data", filters);
  const cart = useSelector((state) => state.cart);
  const wishlist = useSelector((state) => state.wishlist);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  console.log("selectedAddress", selectedAddress);

  useEffect(() => {
    if (filters) {
      setSelectedAddress(filters?.address);
    }
  }, [filters]);

  const setSigninModalHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "signin",
      })
    );
  };

  const onClickSignoutHandler = () => {
    dispatch(userActions.logout());
    dispatch(modalActions.toggleModal());
    navigate("/");
  };

  const placeholderStyle = {
    color: "black",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
    paddingLeft: "8px",
  };

  const customArrow = (
    <img
      src="/img/icons/dropdown-arrow.svg"
      alt="arrow"
      style={{ width: "24px", height: "24px", marginTop: "3px" }}
    /> // Customize the arrow color as needed
  );

  const locationFinder = (place) => {
    if (place.geometry && place.geometry.location) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const formattedAddress = place.formatted_address;
      const placeName = place.address_components[0]?.long_name;
      console.log("Latitude:", latitude);
      console.log("Longitude:", longitude);
      // setLatitude(latitude);
      // setLongitude(longitude);
      dispatch(
        filterActions.togglelocation({
          latitude: latitude,
          longitude: longitude,
          address: formattedAddress,
          place: placeName,
        })
      );
      // form.setFieldValue("address", formattedAddress);
      // You can set the latitude and longitude in your state or dispatch an action here
    }
  };

  return (
    <ul className={`${classes.navbarList} ${className}`}>
      <img
        src="/img/icons/close-pink.svg"
        alt="close"
        className={classes.closeIcon}
        onClick={onClose}
      />
      <div className={classes.accountItem}>
        {!isLoggedIn && (
          <NavbarItem onClick={setSigninModalHandler}>
            <img
              src="/img/icons/login2.svg"
              alt="login"
              className={classes.navIcon}
            />
            Login
          </NavbarItem>
        )}
        {isLoggedIn && (
          <NavbarItem>
            <img
              src="/img/icons/login2.svg"
              alt="login"
              className={classes.navIcon}
            />
            <p style={{ color: "#FF6666" }}>
              Hello, {user?.user?.name?.split(" ")[0]}
            </p>
          </NavbarItem>
        )}
      </div>
      <div className={classes.list}>
        <div className={classes.iconLinks}>
          <img
            src="/img/icons/location-black.svg"
            alt="wishlist"
            style={{ marginLeft: "0.75rem" }}
            // className={classes.navIcon}
          />
          <Autocomplete
            className="custom-autocomplete"
            defaultValue={selectedAddress}
            style={{
              width: "100%",
              borderRadius: "10px",
              height: "30px",
              padding: "8.5px 11px",
              border: "1px solid #fff",
              fontSize: "16px",
              color: "#0d0a19",
            }}
            apiKey={GOOGLE_MAP_API_KEY}
            onPlaceSelected={locationFinder}
          />
          {/* <Select
            suffixIcon={customArrow}
            placeholder={<span style={placeholderStyle}>Location</span>}
            rootClassName="navbar-select"
            // dropdownStyle={{ maxHeight: "35vh", height: "13vh" }}
            defaultValue="Kochi"
          >
            <Option value="Kochi">Kochi</Option>
            <Option value="Thrissur">Thrissur</Option>
            <Option value="Kannur">Kannur</Option>
          </Select> */}
        </div>
        {/* <div className={classes.listItem}>
          <NavbarItem
          //   onClick={setSigninModalHandler}
          >
            <img
              src="/img/icons/venues.svg"
              alt="venues"
              className={classes.navIcon}
            />
            Venues
          </NavbarItem>
        </div> */}
        {/* <div className={classes.listItem}>
          <NavbarItem
          //   onClick={setSigninModalHandler}
          >
            <img
              src="/img/icons/packages.svg"
              alt="packages"
              className={classes.navIcon}
            />
            Packages
          </NavbarItem>
        </div> */}
        {/* <hr className={classes.line} /> */}
        <div className={classes.listItem}>
          <NavbarItem
            onClick={() => {
              // if (!isLoggedIn) {
              //   setSigninModalHandler.bind();
              // } else {
              //   dispatch(modalActions.toggleModal());
              // }
            }}
            pageLink={APP_PREFIX_PATH + "/blogs"}
          >
            <img
              src="/img/icons/blog-icon.svg"
              alt="bookings"
              className={classes.navIcon}
            />
            Blogs
          </NavbarItem>
        </div>
        <div className={classes.listItem}>
          <NavbarItem
            onClick={!isLoggedIn && setSigninModalHandler.bind()}
            pageLink={isLoggedIn && APP_PREFIX_PATH + "/wishlist"}
          >
            {wishlist?.totalItems > 0 && (
              <Badge
                count={wishlist?.totalItems}
                overflowCount={9}
                style={{
                  backgroundColor: "#FF6666",
                }}
              >
                <img
                  src="/img/icons/wishlist.svg"
                  alt="wishlist"
                  className={classes.navIcon}
                />
              </Badge>
            )}
            {(!wishlist?.totalItems || wishlist?.totalItems <= 0) && (
              <img
                src="/img/icons/wishlist.svg"
                alt="wishlist"
                className={classes.navIcon}
              />
            )}
            {/* <img
              src="/img/icons/wishlist.svg"
              alt="wishlist"
              className={classes.navIcon}
            /> */}
            Wishlist
          </NavbarItem>
        </div>
        <div className={classes.listItem}>
          <NavbarItem
            onClick={!isLoggedIn && setSigninModalHandler.bind()}
            pageLink={isLoggedIn && APP_PREFIX_PATH + "/cart"}
          >
            {cart?.cartTotal > 0 && (
              <Badge
                count={cart?.cartTotal}
                // overflowCount={9}
                style={{
                  backgroundColor: "#FF6666",
                }}
              >
                <img
                  src="/img/icons/cart-black.svg"
                  alt="cart"
                  className={classes.navIcon}
                />
              </Badge>
            )}
            {(!cart?.cartTotal || cart?.cartTotal === 0) && (
              <img
                src="/img/icons/cart-black.svg"
                alt="cart"
                className={classes.navIcon}
              />
            )}
            Cart
          </NavbarItem>
        </div>
        <div className={classes.listItem}>
          <NavbarItem
            onClick={() => {
              if (!isLoggedIn) {
                setSigninModalHandler.bind();
              } else {
                dispatch(modalActions.toggleModal());
              }
            }}
            pageLink={isLoggedIn && APP_PREFIX_PATH + "/bookings"}
          >
            <img
              src="/img/icons/bookings.svg"
              alt="bookings"
              className={classes.navIcon}
            />
            Bookings
          </NavbarItem>
        </div>
        <div className={classes.listItem}>
          <NavbarItem
            onClick={!isLoggedIn && setSigninModalHandler.bind()}
            pageLink={isLoggedIn && APP_PREFIX_PATH + "/profile"}
          >
            <img
              src="/img/icons/settings.svg"
              alt="settings"
              className={classes.navIcon}
            />
            Profile Settings
          </NavbarItem>
        </div>
      </div>
      <div className={classes.logoutWrapper}>
        <Button
          option="uncolored"
          className={classes.logout}
          onClick={onClickSignoutHandler.bind()}
        >
          Logout
        </Button>
      </div>
    </ul>
  );
};

export default SideNavbarList;
